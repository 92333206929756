export default {
  proxy_path: "https://vgl8yws7p7.execute-api.us-east-1.amazonaws.com/api",
  proxy_path_geojson_api: "https://h242dwgbma.execute-api.us-east-1.amazonaws.com/api",
  google_api_key: "AIzaSyDVZjTV9W6b88zZ1cSax2y9SVBUckHtjCo",
  main_company_term_id: "191",
  crm_base_url: "https://crm.riotinto.forwoodsafety.com",
  forwood_id_url: "https://id.riotinto.forwoodsafety.com?redirect_uri=https://mapreport.riotinto.forwoodsafety.com",
  custom_tile_url: "https://mapoverlays.geoeditor.riotinto.forwoodsafety.com",
  Auth: {
    userPoolId: "us-east-1_gbRwzUwDX",
    userPoolWebClientId: "5669riomc2n2e77gtb38h13ken",
    cookieStorage: {
      domain: ".riotinto.forwoodsafety.com",
      secure: true
    }
  }
};
